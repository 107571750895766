import React from 'react';
import ImportGTFSView from "./ImportGTFSView";
import {City} from "../../models/City";
import TaskGetCitiesList from "../../ws/TaskGetCitiesList";
import TaskImportGTFS from "../../ws/TaskImportGTFS";

export interface ResponseGTFS {
    success: boolean
    message: string | null
    error: string | null
}

interface state {
    cities: City[]
    file: File | null
    response: ResponseGTFS | null
}

class ImportGTFSContainer extends React.Component {

    public state: state = {cities: [] as City[], file: null, response: null};

    public componentWillMount() {
        new TaskGetCitiesList().get()
            .then(cities => this.setState({cities: cities}))
    }

    public importGTFS(city: string, file: File, stopLoading: () => void) {
        new TaskImportGTFS().get(city, file)
            .catch(
                (response) => {
                    this.setState({response: {success:false, error: response.message} as ResponseGTFS});
                }
            )
            .then(
                (response) => {
                    if (response !== undefined) {
                        this.setState({response: response});
                    }
                    stopLoading();
                }
            )
    }

    public render() {
        return <ImportGTFSView
            cities={this.state.cities}
            importGTFS={this.importGTFS.bind(this)}
            response={this.state.response}
        />;
    }

}

export default ImportGTFSContainer;