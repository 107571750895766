import Urls from "./Urls";
import {AuthManager} from "@cuatroochenta/co-admin-react-library";
import {City} from "../models/City";

export default class TaskGetCitiesList {

    private getPostInfo() {
        return {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + AuthManager.getAuthToken()
            },
            body: JSON.stringify({maxPerPage: 20})
        }
    }

    public get() {
        return fetch(Urls.URL_GET_CITIES, this.getPostInfo())
            .then(response => response.json())
            .then(data => {return data.data.results as City[]});
    }
}