import React from 'react';
import {City} from "../../models/City";
import {ResponseGTFS} from "./ImportGTFSContainer";
import {
    LoadingView,
    SelectorField,
    FieldFValue,
    UploadField,
    Button,
    Icon,
    IconsBase,
} from "@cuatroochenta/co-admin-react-library";
import './importGTFSView.scss';

interface ImportGTFSViewProps {
    cities: City[]
    importGTFS: (city: string, file: File, stopLoading: () => void) => void
    response: ResponseGTFS | null
}

interface state {
    city: string
    file: File | null
    loading: boolean,
    helperTextFileSelector: string,
    helperTextFieldSelector: string,
}

class ImportGTFSView extends React.Component<ImportGTFSViewProps> {

    public state: state = {
        city: '',
        file: null,
        loading: false,
        helperTextFileSelector: '',
        helperTextFieldSelector: '',
    };

    public onUploadFile(file: File | null): void {
        this.setState({
            file: file,
            helperTextFileSelector: '',
        });
    }

    public handleRemoveFile(): void {
        this.setState({
            file: null,
            helperTextFileSelector: 'Campo obligatorio',
        });
    }

    onChangeCity(value: string): void {
        this.setState({
            city: value,
            helperTextFieldSelector: !value ? 'Campo obligatorio' : '',
        });
    }

    importGTFS(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault()
        this.setState({
            helperTextFileSelector: this.state.file ? '' : 'Campo obligatorio',
            helperTextFieldSelector: this.state.city && this.state.city !== '' ? '' : 'Campo obligatorio'
        });
        if (this.state.file !== null && this.state.city && this.state.city !== '' && !this.state.loading) {
            this.setState({loading: true});
            this.props.importGTFS(this.state.city, this.state.file, () => {
                this.setState({loading: false})
            })
        }
    }

    private renderGenerateButton() {
        return (
            <div className="row">
                <div className="col">
                    <Button
                        type={'submit'}
                        content={
                        <>
                            <Icon
                                fontAwesomeClass={IconsBase.QR}
                            />
                            <span>{` Importar GTFS`}</span>
                        </>
                    }
                    />
                </div>
            </div>
        )
    }

    private renderMessageCard() {
        if (this.props.response === null || this.state.loading) {
            return null;
        }

        if (this.props.response.success) {
            return this.renderMessage(this.props.response.message);
        }

        return this.renderMessage(this.props.response.error, {color: "red"});
    }

    private renderMessage(message: string | null, style: {} = {}) {
        return (
            <div className="col-md-12">
                <div className="card shadow p-3">
                    <div className="card-body">
                        <div className="message">
                            <label style={style}>{message}</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderFileSelector(): React.ReactNode {

        return (
            <div className="col-md-12">
                <div className="form-group">
                    <UploadField
                        value={!!this.state.file ? this.state.file.name : ''}
                        field={'file'}
                        type={'file'}
                        disabled={false}
                        removeFile={() => this.handleRemoveFile()}
                        isEditScreen={true}
                        name={''}
                        label={'File*'}
                        taskUpload={(e: any) => this.onUploadFile(e.target.files ? e.target.files[0] : null)}
                        helperText={this.state.helperTextFileSelector}
                        onClickUploadButton={() => this.setState({helperTextFileSelector: 'Campo obligatorio'})}
                    />
                </div>
            </div>
        );
    }

    private renderCities(): React.ReactNode {
        let cities = [] as FieldFValue[];
        cities = this.props.cities.map((city) => ({id: city.oid, description: city.name, object: city}))

        return (
            <div className="col-md-6">
                <SelectorField
                    label={'Ciudad*'}
                    value={this.state.city}
                    disabled={false}
                    onChange={(value: string) => this.onChangeCity(value)}
                    values={cities}
                    helperText={this.state.helperTextFieldSelector}
                    required={true}
                />
            </div>
        );
    }

    public render() {
        if (this.state.loading) {
            return <LoadingView loading={this.state.loading}/>;
        }

        return (
            <div className={"component-import-gtfs-view"}>
                {this.renderMessageCard()}
                <div className="col-md-12">
                    <div className="card shadow p-3">
                        <div className="card-body">
                            <form onSubmit={(e) => this.importGTFS(e)}>
                                <div className={"row"}>
                                    {this.renderCities()}
                                </div>
                                <div className={"row"}>
                                    {this.renderFileSelector()}
                                </div>
                                {this.renderGenerateButton()}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImportGTFSView;